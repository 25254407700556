import React from 'react';
import { useForm } from 'react-final-form';
import { FormSubscription } from 'final-form';

import Button, { ButtonProps } from '@ui/components/Button';
import Box from '@ui/components/Box';

export type SubmitButtonWrapProps = {
	id?: string;
	formComponentName?: string;
	showLoading?: boolean;
	submitting?: boolean;
	disableOnSubmitSuccess?: boolean;
	iconLoading?: boolean;
	disbableIfInvalid?: boolean;
	submitAfter?: (callback: () => Promise<any> | undefined) => void;
	pt?: number;
	pb?: number;
} & Omit<ButtonProps, 'type'>;

type SubmitButtonProps = {
	loading?: boolean;
	iconLoading?: boolean;
	pt?: number;
	pb?: number;
} & Omit<ButtonProps, 'type'>;

const SubmitButton: React.FC<SubmitButtonProps> = ({
	loading,
	disabled,
	children,
	iconLoading,
	size = 'large',
	variant = 'contained',
	color = 'primary',
	fullWidth = true,
	sx,
	pt,
	pb,
	...rest
}) => {
	return (
		<Box
			className="submit-button-wrapper"
			pt={pt || 4}
			pb={pb || 2}
			width={fullWidth ? '100%' : undefined}
			sx={sx}
		>
			{loading ? (
				<Button
					disabled
					type="button"
					variant={variant}
					color={color}
					size={size}
					fullWidth={fullWidth}
					onClick={() => undefined}
					sx={sx}
					{...rest}
				>
					{iconLoading ? children : '...Um momento, favor'}
				</Button>
			) : (
				<Button
					type="button"
					variant={variant}
					color={color}
					size={size}
					fullWidth={fullWidth}
					disabled={disabled}
					sx={sx}
					{...rest}
				>
					{children}
				</Button>
			)}
		</Box>
	);
};

const SubmitButtonWrap: React.FC<SubmitButtonWrapProps> = ({
	formComponentName,
	showLoading,
	submitting: propSubmitting,
	disbableIfInvalid,
	disableOnSubmitSuccess = true,
	submitAfter,
	...rest
}) => {
	const [checking, setChecking] = React.useState(false);
	const [subscription, setSubscription] = React.useState<FormSubscription>(
		{},
	);
	const { submit, subscribe } = useForm(formComponentName);
	React.useEffect(() => {
		// TODO useFormSubscription hook instead
		return subscribe(
			({
				submitting,
				submitSucceeded,
				invalid,
				submitFailed,
				hasSubmitErrors,
				hasValidationErrors,
				dirtySinceLastSubmit,
				modifiedSinceLastSubmit,
			}) => {
				setChecking(false);
				setSubscription({
					submitting,
					submitSucceeded,
					invalid,
					submitFailed,
					hasSubmitErrors,
					hasValidationErrors,
					dirtySinceLastSubmit,
					modifiedSinceLastSubmit,
				});
			},
			{
				submitting: true,
				submitSucceeded: true,
				invalid: true,
				submitFailed: true,
				hasSubmitErrors: true,
				hasValidationErrors: true,
				dirtySinceLastSubmit: true,
				modifiedSinceLastSubmit: true,
			},
		);
	}, [subscribe]);
	const handleSubmit = React.useCallback(() => {
		if (submitAfter) {
			setChecking(true);
			submitAfter(() => submit());
		} else {
			submit();
		}
	}, [submitAfter, submit]);
	const {
		submitting,
		submitSucceeded,
		invalid,
		submitFailed,
		hasSubmitErrors,
		hasValidationErrors,
		dirtySinceLastSubmit,
		modifiedSinceLastSubmit,
	} = subscription;
	const error =
		!submitting &&
		submitFailed &&
		(hasSubmitErrors || hasValidationErrors) &&
		!(dirtySinceLastSubmit || modifiedSinceLastSubmit);
	return (
		<SubmitButton
			{...rest}
			loading={
				!error &&
				(checking ||
					((propSubmitting || submitting) &&
						!showLoading &&
						!submitSucceeded))
			}
			disabled={
				error ||
				rest.disabled ||
				(disbableIfInvalid && invalid) ||
				propSubmitting ||
				submitting ||
				checking ||
				(submitSucceeded && disableOnSubmitSuccess)
			}
			onClick={handleSubmit}
		/>
	);
};

export default SubmitButtonWrap;
