import React from 'react';
import { useMutation } from '@apollo/client';

import {
	MUTATION_SET_STATUS,
	MUTATION_RESET_STATUS,
	MUTATION_SET_CONNECTION_STATUS,
} from '@common-lib/modules/status/graphql';
import { StatusType } from '@common-lib/modules/status/config';

const useStatus = () => {
	const [setStatus] = useMutation(MUTATION_SET_STATUS);
	const [resetStatus] = useMutation(MUTATION_RESET_STATUS);
	const [setConnectionStatus] = useMutation(MUTATION_SET_CONNECTION_STATUS);

	const addStatus = React.useCallback(
		(item: { message: string; type: StatusType }) => {
			const variables = {
				input: {
					type: item.type,
					message: item.message || null,
					// action: item.action || null,
					// autoHideDuration: item.autoHideDuration || null,
				},
			};
			setStatus({
				variables,
			});
		},
		[],
	);

	const addInfo = React.useCallback((message: string) => {
		addStatus({
			type: StatusType.INFO,
			message,
			// action: item.action,
			// autoHideDuration: item.autoHideDuration,
		});
	}, []);

	const addError = React.useCallback((message: string) => {
		addStatus({
			type: StatusType.ERROR,
			message,
			// action: item.action,
			// autoHideDuration: item.autoHideDuration,
		});
	}, []);

	const addValidationError = React.useCallback((message: string) => {
		addStatus({
			type: StatusType.VALIDATIONERROR,
			message,
			// action: item.action,
			// autoHideDuration: item.autoHideDuration,
		});
	}, []);

	const addSuccess = React.useCallback((message: string) => {
		addStatus({
			type: StatusType.OK,
			message,
			// action: item && item.action,
			// autoHideDuration: item && item.autoHideDuration,
		});
	}, []);

	const addConnectionError = React.useCallback(() => {
		setConnectionStatus({ variables: { connected: false } });
	}, []);

	const removeConnectionError = React.useCallback(() => {
		setConnectionStatus({ variables: { connected: true } });
	}, []);

	const clearStatus = React.useCallback((types: StatusType[]) => {
		// workaround for react-final-form useState error
		setTimeout(() => resetStatus({ variables: { input: { types } } }), 0);
	}, []);

	return {
		addInfo,
		addError,
		addSuccess,
		clearStatus,
		addConnectionError,
		removeConnectionError,
		addValidationError,
	};
};

export default useStatus;
