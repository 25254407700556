import deepmerge from 'deepmerge';

export const getNastedValue = (data: Record<string, any>, path: string) => {
	return path.split('.').reduce((acc, i) => {
		acc = acc[i];
		return acc;
	}, data) as any;
};

export const convertDotKey = (
	errObj: Record<string, any>,
	baseObject: Record<string, any> = {},
) => {
	const result: Record<string, any> = {};
	Object.entries(errObj).forEach(([key, value]) => {
		key.split('.').reduce(
			(r, e, i, arr) => (r[e] = r[e] || (arr[i + 1] ? {} : value[0])),
			result,
		);
	});
	return deepmerge(baseObject, result);
};

export const removeNullUndefinedProperties = (
	obj: Record<string, any>,
): Record<string, any> => {
	for (const prop in obj) {
		if (obj[prop] === null || obj[prop] === undefined) {
			delete obj[prop];
		} else if (typeof obj[prop] === 'object') {
			removeNullUndefinedProperties(obj[prop]); // Recursively check nested objects
			if (Object.keys(obj[prop]).length === 0) {
				delete obj[prop]; // Delete the property if the nested object is empty
			}
		}
	}
	return obj;
};
