import React from 'react';
import {
	ValidationErrors,
	FORM_ERROR,
	setIn,
	FormApi,
	SubmissionErrors,
} from 'final-form';
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import { ValidationError, Schema } from 'yup';
import deepmerge from 'deepmerge';
import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from '@interfaces';

import useStatus from '@common-lib/modules/status/hooks';
import { StatusType } from '@common-lib/modules/status/config';
import ErrorScrollSpy from '@common-lib/components/form/ErrorScrollSpy';
import { convertDotKey } from '@common-lib/helpers/objectHelper';

export type AsyncFormProps = Omit<
	FormProps,
	'render' | 'component' | 'children'
> & {
	showSuccess?: boolean;
	children: React.FC<
		Partial<
			FormRenderProps<Record<string, any>, Partial<Record<string, any>>>
		>
	>;
	name: string;
	validationSchema?: Schema;
	resetOnSuccess?: boolean;
	allowNull?: boolean;
	preSubmitAction?: () => Promise<any>;
	onSubmitSuccess?: (data: Record<string, any>) => any;
	transformValues?: (data: Record<string, any>) => any;
	preSubmitTransform?: (data: any) => any;
	validationError?: Record<string, any>;
};

export type RenderProps = Partial<FormRenderProps>;

const sanitizeEmptyValues = (
	initialValues: Record<string, any>,
	values: Record<string, any>,
) => {
	// For every field initially provided, we check whether it value has been removed
	// and set it explicitly to an empty string
	if (!initialValues) return values;
	const initialValuesWithEmptyFields = Object.keys(initialValues).reduce(
		(acc, key) => {
			if (values[key] instanceof Date || Array.isArray(values[key])) {
				(acc as any)[key] = values[key];
			} else if (
				typeof values[key] === 'object' &&
				values[key] !== null
			) {
				(acc as any)[key] = sanitizeEmptyValues(
					initialValues[key],
					values[key],
				);
			} else {
				(acc as any)[key] =
					typeof values[key] === 'undefined' ? null : values[key];
			}
			return acc;
		},
		{},
	);

	// Finally, we merge back the values to not miss any which wasn't initially provided
	return deepmerge(initialValuesWithEmptyFields, values);
};

const convertGraphQLErrors = (err: GraphQLErrors) => {
	const message = 'Alguma coisa deu errado';
	try {
		let errors: Record<string, any> = {};

		if (err && Array.isArray(err)) {
			for (let x = 0; x < err.length; x++) {
				const error: GraphQLError = err[x];
				const { message, extensions } = error;
				const { validationErrors = {}, state } = extensions || {};
				const sState: any = state;
				if (sState) {
					Object.keys(sState).forEach(field => {
						errors[field] = Array.isArray(sState[field])
							? sState[field][0]
							: sState[field];
					});
				} else if (validationErrors) {
					errors = convertDotKey(validationErrors);
					break;
				}
				errors.error = error.message || message; // eslint-disable-line
			}
		}
		return errors;
	} catch (err1) {
		return {
			error: message,
		};
	}
};

const defaultSubscription = {
	submitting: false,
	pristine: false,
	invalid: false,
	values: false,
	validating: false,
	errors: false,
};

const AsyncForm: React.FC<AsyncFormProps> = ({
	subscription = defaultSubscription,
	initialValues,
	children,
	onSubmit,
	validate,
	validationSchema,
	keepDirtyOnReinitialize = true,
	mutators,
	decorators,
	validateOnBlur,
	debug,
	name,
	transformValues,
	preSubmitAction,
	preSubmitTransform,
	onSubmitSuccess,
	resetOnSuccess,
	showSuccess,
	validationError,
	allowNull,
}) => {
	// use memoized values on form if performance is very low it may cause some other problems
	// const mutatorsMemo = useMemo(() => mutators, [mutators]);
	// const subscriptionMemo = useMemo(() => subscription, [subscription]);
	// const decoratorsMemo = useMemo(() => decorators, [decorators]);
	const { addValidationError, addSuccess, addError, clearStatus } =
		useStatus();

	const handleSubmitSuccess = React.useCallback(
		(form: FormApi<Record<string, any>, Record<string, any>>, res: any) => {
			if (showSuccess) {
				addSuccess('Submetido com sucesso!');
			}
			if (resetOnSuccess && form.reset) {
				setTimeout(() => {
					form.reset();
				}, 100);
			}
			if (onSubmitSuccess) {
				onSubmitSuccess(res);
			}
		},
		[onSubmitSuccess, resetOnSuccess, showSuccess],
	);
	const handleValidate = React.useCallback(
		async (values: { [key: string]: any }) => {
			try {
				clearStatus([StatusType.ERROR, StatusType.VALIDATIONERROR]);

				const newValues = transformValues
					? transformValues(values)
					: { ...values };
				if (validationSchema) {
					try {
						await validationSchema.validate(newValues, {
							context: { ...values, ...newValues }, // we want access to all values
							abortEarly: false,
						});
					} catch (err) {
						console.log('error validating', err.message);
						if (!(err instanceof ValidationError)) {
							addValidationError('Alguma coisa deu errado.');
							return {
								[FORM_ERROR]: 'Alguma coisa deu errado',
							};
						}
						let errors: ValidationErrors = {};
						if (err && err.inner) {
							if (err.inner.length === 0 && err.path) {
								setIn(errors, err.path, err.message);
							} else {
								for (const error of err.inner) {
									if (error.path && !errors[error.path]) {
										errors = setIn(
											errors,
											error.path,
											error.message,
										);
									}
								}
							}
						}
						return errors;
					}
				}
				return validate ? validate(newValues) : {};
			} catch (err) {
				if (
					err instanceof ValidationError &&
					!err.message.includes('errors occurred')
				) {
					addValidationError(err.message);
					return err;
				} else if (err instanceof ValidationError) {
					return err;
				}
				throw err;
			}
		},
		[
			transformValues,
			validationSchema,
			validate,
			clearStatus,
			addValidationError,
		],
	);

	const handleSubmit = React.useCallback(
		async (
			values: Record<string, any>,
			form: FormApi<Record<string, any>, Record<string, any>>,
			callback?: (errors?: SubmissionErrors) => void,
		) => {
			try {
				clearStatus([StatusType.ERROR, StatusType.VALIDATIONERROR]);
				if (preSubmitAction) {
					await preSubmitAction();
				}
				let data = transformValues ? transformValues(values) : values;

				data = preSubmitTransform ? preSubmitTransform(data) : data;
				if (allowNull) {
					let initData = transformValues
						? transformValues(initialValues)
						: initialValues;
					initData = preSubmitTransform
						? preSubmitTransform(initData)
						: initData;
					data = sanitizeEmptyValues(initData, data);
				}
				const res = await onSubmit(data, form, callback);
				if (res === undefined) {
					throw new Error(
						`You're not returning submit response for Form: ${name}`,
					);
				}
				handleSubmitSuccess(form, res);
			} catch (err) {
				const message = `Alguma coisa deu errado`;
				// Convert for graphQL errors
				if (err.graphQLErrors) {
					if (typeof err.graphQLErrors === 'string') {
						addValidationError(err.graphQLErrors);
						return {
							[FORM_ERROR]: err.graphQLErrors,
						};
					}
					const errors = convertGraphQLErrors(err.graphQLErrors);
					if (Object.keys(errors).length) {
						if (typeof errors.error === 'string') {
							addValidationError(errors.error);
						}
						return {
							[FORM_ERROR]:
								typeof errors.error === 'string'
									? errors.error
									: `Por favor, corrige o formulário`,
							...errors,
							...(validationError || {}),
						};
					} else if (err.message.startsWith('ValidationError')) {
						addValidationError(err.message);
					}
				} else if (err.message.startsWith('ValidationError')) {
					addValidationError(err.message);
				}
				console.warn(err);
				// addError(message);
				return {
					[FORM_ERROR]: message,
				};
			}
		},
		[
			handleSubmitSuccess,
			transformValues,
			preSubmitTransform,
			preSubmitAction,
			onSubmit,
			name,
			validationError,
			clearStatus,
			initialValues,
			allowNull,
		],
	);
	return (
		<Form
			name={name}
			onSubmit={handleSubmit}
			validate={handleValidate}
			validateOnBlur={validateOnBlur}
			initialValues={initialValues}
			keepDirtyOnReinitialize={keepDirtyOnReinitialize}
			mutators={mutators}
			subscription={subscription}
			decorators={decorators}
			// mutators={mutatorsMemo}
			// subscription={subscriptionMemo}
			// decorators={decoratorsMemo}
			debug={debug}
		>
			{(args: any) => (
				<>
					{children(args)}
					<ErrorScrollSpy />
				</>
			)}
		</Form>
	);
};

export default AsyncForm;
